import React from 'react'
import { Link } from 'gatsby'

import SEO from '../../components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from '../../components/layout'
import { ApplyAndWhatsNextSections, PositionIcon } from '../../components/pages/CareersPage'
import { Nav } from '../../components/nav'
import { LdJsonJobPosting, HeaderLink } from '../../components/semanticdata'
import Analytics from '../../components/analytics'

const CareerPositionPage = () => (
  <Layout className="bg-primary-1 text-primary-3">
    <Analytics />
    <SEO
      title="Software Development Engineer in Test"
      pageUrl="/careers/sdet/"
      type="article"
      cardImage="/img/tumbacardjobs1.png"
      description="Do you consider yourself a rare breed of IT Professional? An engineer who knows every tiny detail of the products you've worked on, Let's Talk!"
    />
    <LdJsonJobPosting
      title="Software Development Engineer in Test"
      description="Do you consider yourself a rare breed of IT Professional? An engineer who knows every tiny detail of the products you've worked on, Let's Talk!"
      publishDate="2024-04-05T13:09:57.000Z"
      minSalary="6500.00"
      maxSalary="8000.00"
    />
    <MainWithMenu className="relative grid">
      <Header className="z-20 pt-6 pb-24">
        <HeaderLink className="text-primary-2" />
        <Link to="/careers/">
          <div className="py-12 text-left">
            <h1 className="py-4 text-4xl lg:text-5xl text-primary-3 text-shadow">Careers</h1>
          </div>
        </Link>
      </Header>
      <Deco className="z-10 bg-primary-2" decoType={2} before after gridRow={2} />
      <Section className="z-20 lg:mr-48" gridRow={2}>
        <PositionIcon className="w-12 sm:w-20 text-primary-1 fill-current" />
        <h2 className="text-3xl lg:text-4xl text-primary-1">Software Development Engineer in Test</h2>
        <div className="mt-4 mb-16 text-lg lg:text-xl tracking-normal">
          <p className="sm:mt-6 font-normal">
            You consider yourself a rare breed of IT Professional, an engineer who knows every tiny detail of the products you've worked on. You don't call yourself a QA or a simpleton software developer. You have passion for quality and solid automation
            experience. <strong className="text-primary-1 font-normal">You are a SDET professional</strong> with advanced programming skills who can create automation testing tools and frameworks to assist the manual testing process.
          </p>
          <p className="sm:mt-6 font-normal">
            Then <strong className="text-primary-1 font-normal">let's talk</strong> 'cause we have an opportunity you cannot just ignore - to shape the future of mobility!
          </p>
          <ul className="pl-10 mt-2 list-disc font-normal">
            <li>Help build and validate distributed compute platform running on a vehicle.</li>
            <li>Build a testing framework to enable automated verification of native on vehicle microservices integrity and operations.</li>
          </ul>
          <p className="sm:mt-6 font-normal">
            <em>What we are primarily looking for is a track record of building things that work and passion to make a better product.</em>
          </p>
        </div>
        <div className="grid gap-12 grid-cols-1 lg:grid-cols-2">
          <div>
            <h3 className="text-xl lg:text-2xl text-primary-1">Main Responsibilities</h3>
            <div className="mt-4 text-lg lg:text-xl font-normal tracking-normal">
              <ul className="pl-10 list-disc">
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Collaborate</strong> with cross functional teams to define, design and ship new features.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Understanding</strong> the flow of code in the whole product and how it interacts with different components. Handle the end to end software lifecycle.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Develop</strong> testing frameworks, tools and DSLs to validate the correctness and the robustness of multi components distributed software systems.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Design</strong> and build a robust codebase that enables rapid exploration and evaluation of different approaches.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Understanding</strong> project documents and product objectives to create and execute test cases.
                </li>
                <li className="mt-4">Setting up, maintaining and performing test automation frameworks on multiple platform products, such as desktop, embedded systems, mobile and web.</li>
                <li className="mt-4">Researching test tools, methodologies, trends and upgrading existing practices and processes.</li>
                <li className="mt-4">Managing the final quality of production release.</li>
              </ul>
            </div>
          </div>
          <div>
            <h3 className="text-xl lg:text-2xl text-primary-1">Core Requirements</h3>
            <div className="mt-4 text-lg lg:text-xl font-normal tracking-normal">
              <ul className="pl-10 list-disc">
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Proficiency</strong> in one or more of the following programming languages: C++, Java, Python.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Advanced knowledge</strong> of software engineering principles including requirements gathering, software design, source control management, build processes, code reviews, testing methods and
                  process management methodology.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Good understanding</strong> of communication protocols (mainly TCP/IP), application transport and network infrastructure protocols.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Good working knowledge</strong> of Linux OS and it's network stack.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">9+ years</strong> of software engineering experience.
                </li>
                <li className="mt-4">Ability to analyze, identify and resolve performance bottlenecks.</li>
                <li className="mt-4">Knowledge of test methodologies and their corresponding tools.</li>
                <li className="mt-4">The ability to recognize risks and errors in application.</li>
                <li className="mt-4">Self-organized, proactive and good communicator.</li>
              </ul>
            </div>
          </div>
        </div>
      </Section>
      <ApplyAndWhatsNextSections position="Software Development Engineer in Test" active={false} />
      <Nav gridRow={7} className="z-20 text-primary-2" />
    </MainWithMenu>
  </Layout>
)

export default CareerPositionPage
